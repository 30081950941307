import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import { lazy } from "react";
import ThemeSetter from "./ThemeSetter/ThemeSetter";
import "../src/pages/SeventhJournal/MyJournal/MyJournal.css";
import "../src/pages/SeventhJournal/Checkout/Checkout.css";
import "../src/pages/SeventhJournal/Goals/Goals.css";
import "../src/pages/SeventhJournal/JournalInsights/Insights.css";
import "../src/pages/SeventhJournal/OrderInformation/OrderInformation.css";
import "../src/pages/SeventhJournal/PrintDiary/PrintDiary.css";
import "../src/pages/SeventhJournal/Resources/Resources.css";
import "../src/pages/SeventhJournal/Settings/Settings.css";
import "../src/pages/SeventhJournal/Statistics/Statistics.css";
import "../src/pages/SeventhJournal/Summary/Summary.css";
import "../src/shared/css/journalcss.css";

import "./firebase.js";

import ReCAPTCHA from "react-google-recaptcha";

// Components
import Header from "./component/Header";

import "react-toastify/dist/ReactToastify.css";

import { ToastContainer, toast } from "react-toastify";

import {
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import { CheckPathTheme, toastobj } from "./commonutilities";

import {
  appreview,
  getMultipleAppConf,
  getAcessToken,
} from "./services/paymentService";
import { v4 as uuidv4 } from "uuid";
import { supabase } from "./index";

// import StripeTest from "./component/StripeTest";

import { useDispatch, useSelector } from "react-redux";
import Loader from "./reusedElements/Loader";

import Home from "../src/pages/Home";
import { showSeventhArc } from "./bootapi";
import { getFirebaseToken, messaging, onMessageListener } from "./firebase.js";
import { onMessage } from "firebase/messaging";
import { clearToken, setToken } from "./Features/CommonSlice.js";

const LandingPage = lazy(() =>
  import("./pages/SeventhJournal/LandingPage/LandingPage")
);
const NewComingSoon = lazy(() =>
  import("./pages/ComingSoonPages/NewComingSoon")
);
const NewDreamAssitant = lazy(() =>
  import("./pages/ComingSoonPages/NewDreamAssitant")
);
const NewInstructionalVideos = lazy(() =>
  import("./pages/ComingSoonPages/NewInstructionalVideos")
);
const AddEntry = lazy(() => import("./pages/SeventhJournal/Entry/AddEntry"));
const AddGratitude = lazy(() =>
  import("./pages/SeventhJournal/Entry/AddGratitude")
);
const Summary = lazy(() => import("./pages/SeventhJournal/Summary/Summary"));
const GoodHabit = lazy(() =>
  import("./pages/SeventhJournal/GoodHabit/GoodHabit")
);
const Statistics = lazy(() =>
  import("./pages/SeventhJournal/Statistics/Statistics")
);
const Goals = lazy(() => import("./pages/SeventhJournal/Goals/Goals"));
const JournalSetting = lazy(() =>
  import("./pages/SeventhJournal/Settings/JournalSetting")
);
const PrintDiary = lazy(() =>
  import("./pages/SeventhJournal/PrintDiary/PrintDiary")
);
const Checkout = lazy(() => import("./pages/SeventhJournal/Checkout/Checkout"));
const OrderInformation = lazy(() =>
  import("./pages/SeventhJournal/OrderInformation/OrderInformation")
);
const MyJournal = lazy(() =>
  import("./pages/SeventhJournal/MyJournal/MyJournal")
);
const JournalInsights = lazy(() =>
  import("./pages/SeventhJournal/JournalInsights/JournalInsights")
);
const Resources = lazy(() =>
  import("./pages/SeventhJournal/Resources/Resources")
);
const GuestResult = lazy(() => import("./DreamPages/GuestResult"));
const Thankyou = lazy(() => import("./pages/Thankyou"));
const GuestAnalysis = lazy(() => import("./pages/GuestAnalysis"));
const DreamStories = lazy(() => import("./pages/DreamStories"));

const DreamologyArcana = lazy(() => import("./pages/DreamologyArcana"));
const InterConnections = lazy(() => import("./pages/InterConnections"));
const SeventhArcPage = lazy(() => import("./pages/SeventhArcPage"));
const StartMainTest = lazy(() => import("./pages/StartMainTest"));
const ArcanaPersona = lazy(() => import("./pages/ArcanaPersona"));
const InstructionalVideos = lazy(() => import("./pages/InstructionalVideos"));
const BuyGiftCard = lazy(() => import("./pages/BuyGiftCard"));
const Faq = lazy(() => import("./pages/Faq"));
const ContactUs = lazy(() => import("./pages/ContactUs"));
const AnalyzeMyDreamNow = lazy(() => import("./pages/AnalyzeMyDreamNow"));
const AnalysisResult = lazy(() => import("./pages/AnalysisResult"));
const AccountInfo = lazy(() => import("./pages/AccountInfo"));
const AccountSubscription = lazy(() => import("./pages/AccountSubscription"));
const AccountSetting = lazy(() => import("./pages/AccountSetting"));
const BuildYourExperience = lazy(() => import("./pages/BuildYourExperience"));
const PreviousDreamsInsight = lazy(() =>
  import("./pages/PreviousDreamsInsight")
);
const AdditionalDetails = lazy(() => import("./pages/AdditionalDetails"));
const TermsConditions = lazy(() => import("./pages/TermsConditions"));
const ReturnRefundPolicy = lazy(() => import("./pages/ReturnRefundPolicy"));
const DataSubject = lazy(() => import("./pages/DataSubject"));
const AnalyzeYourDreamNow = lazy(() => import("./pages/AnalyzeYourDreamNow"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const BetaRegister = lazy(() => import("./pages/BetaRegister"));
const JournalListing = lazy(() => import("./pages/JournalListing"));
const Unsubscribe = lazy(() => import("./pages/Unsubscribe"));
const MasterLogin = lazy(() => import("./pages/MasterLogin"));
const Reactivation = lazy(() => import("./pages/Reactivation"));
const DreamStatistics = lazy(() => import("./pages/DreamStatistics"));
const Result = lazy(() => import("./DreamPages/Result"));
const FooterSeventhJournal = lazy(() =>
  import("./component/FooterSeventhJournal")
);
const CardPayment = lazy(() => import("./component/cardPayment"));
const StripePaymentPage = lazy(() => import("./component/stripePaymentPage"));
const StripeGiftCardPayment = lazy(() =>
  import("./component/StripeGiftCardPaymentPage")
);
const StripePaymentUpdate = lazy(() =>
  import("./component/stripePaymentUpdate")
);
const PageNotFound = lazy(() => import("./component/PageNotFound"));
const Protected = lazy(() => import("./component/Protected"));
const StripeFreeTrialGiftCard = lazy(() =>
  import("./component/StripeFreeTrialGiftCard")
);

function App() {
  const dispatch = useDispatch();
  const [email, setemail] = useState("");
  const [review, setreview] = useState("");
  const [username, setusername] = useState("");
  const [footernum, setfooternum] = useState("");
  const [footeremail, setfooteremail] = useState("");
  const [noDaysFreeTrial, setNoDaysFreeTrial] = useState("");
  const [captchaKey, setCaptchaKey] = useState("");
  const [feedbackmodal, setfeedbackmodal] = useState(false);
  const [loginmodalhome, setloginmodalhome] = useState(false);
  const [signupmodalhome, setsignupmodalhome] = useState(false);
  const [showtimejournal, setshowtimejournal] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [image, setImage] = useState(null);
  const darkMode = CheckPathTheme()?.darkMode;
  const [capthcaValidity, setCaptchaValidity] = useState(null);
  const [reviewOpen, setReviewOpen] = useState(false);
  const [freemium, setFreemium] = useState(false);
  const [freeCredit, setFreeCredit] = useState(false);
  const [noFreeCredit, setNoFreeCredit] = useState(0);
  const [logout, setLogout] = useState(false);
  const [darkModeFromJournal, setDarkModeFromJournal] = useState(false);
  const [clearState, setClearState] = useState(false);
  const [loggedInNow, setLoggedInNow] = useState(false);
  const [freeTrialDaysLeft, setFreeTrialDaysLeft] = useState(null);
  const [isActiveFreeTrial, setIsActiveFreeTrial] = useState(false);
  const [noOfWords, setNoOfWords] = useState(null);
  const clearHomeState = () => {
    setClearState(true);
    setLoggedInNow(false);
    setFreeTrialDaysLeft(null);
    setIsActiveFreeTrial(false);
  };
  const toggleLoginNow = () => {
    setLoggedInNow(true);
    setClearState(false);
  };

  const handleDarkMode = () => {
    setDarkModeFromJournal(!darkModeFromJournal);
  };

  useEffect(() => {
    const getTokenPermisssion = async () => {
      try {
        // Request permission for notifications
        const permissionResult = await Notification.requestPermission();

        // Check if permission is granted
        if (permissionResult === "granted") {
          // getFirebaseToken(setTokenFound);
          onMessage(messaging, (payload) => {
            // console.log("Message received. ", payload);
            // ...
          });
        } else {
          // setTokenFound(false);
        }
      } catch (error) {
        // console.error("Error requesting notification permission:", error);
      }
    };
    const checkAuthentication = async () => {
      try {
        const session = await supabase.auth.getSession();
      } catch (error) {
        console.error("Error checking authentication:", error);
      }
    };
    const { data } = supabase.auth.onAuthStateChange((event, newSession) => {
      if (event === "TOKEN_REFRESHED") {
        addRefreshToken(newSession?.access_token);
      }
      if (event === "SIGNED_OUT") {
        clearHomeState();
        dispatch(clearToken());
      }
      /* if (event === "SIGNED_IN") {
        setLoggedInNow(true);
      } */
    });
    function addRefreshToken(token) {
      getAcessToken(token).then((res) => {
        if (res?.data?.status) {
          dispatch(setToken(res?.data?.auth_token));
        }
      });
    }

    getTokenPermisssion();
    checkAuthentication();
    return () => {
      // Your cleanup logic here
      data.subscription.unsubscribe();
    };
  }, []);

  onMessageListener()
    .then((payload) => {
      // setShow(true);
      /* setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      }); */
      // console.log(payload);
    })
    .catch((err) => {});

  useEffect(() => {
    const getfooterdetail = () => {
      getMultipleAppConf()
        .then((res) => {
          setNoOfWords(res?.data?.value?.number_of_words);
          setfooteremail(res?.data?.value?.footer_email);
          setfooternum(res?.data?.value?.footer_number);
          setNoDaysFreeTrial(res?.data?.value?.free_trial);
          setCaptchaKey(res?.data?.value?.site_key);
          if (res?.data?.value?.is_freemium == 1) {
            setFreemium(true);
          }
          if (res?.data?.value?.is_credit == 1) {
            setFreeCredit(true);
          }
          setNoFreeCredit(res?.data?.value?.free_credit_limit);
        })
        .catch((err) => {
          // console.log("255", err);
        });
    };
    getfooterdetail();
  }, []);

  const togglefeedback = () => {
    setfeedbackmodal(!feedbackmodal);
    setusername("");
    setemail("");
    setreview("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let temp =
      "https://db.seventhsight.com/storage/v1/object/public/Review_Images/";
    let fileName;
    if (image) {
      // console.log(image);
      const imageNameWithoutSpaces = image.name.replace(/\s+/g, "_");
      fileName = `${uuidv4()}_${imageNameWithoutSpaces}`;
      // console.log(fileName);

      const { data, error } = supabase.storage
        .from("Review_Images")
        .upload(`${fileName}`, image);

      if (error) {
        console.error("Error uploading image:", error.message);
      } else {
        // const imageUrl = data?.Key;
        // Save the imageUrl to state or use it as needed
        // console.log('Image URL:', imageUrl);
      }
    }
    temp = temp + fileName;

    if (!email) {
      toast.error("Please enter email to continue", toastobj);
      return;
    }
    if (!review) {
      toast.error("Please enter review to continue", toastobj);
      return;
    }
    if (!capthcaValidity) {
      toast.error("Please Verify Captcha", toastobj);
      return;
    }
    toast.success(`Success!! Review Sent Successfully`, toastobj);
    togglefeedback();
    let body = {
      review: review,
      attachment: image ? temp : null,
      user_name: username ? username : null,
      user_email: email,
    };
    let href = window.location.href;
    if (href.split("seventharc").length > 1) {
      body["site"] = "SeventhArc";
    } else if (href.split("seventhjournal").length > 1) {
      body["site"] = "SeventhJournal";
    } else {
      body["site"] = "SeventhSight";
    }
    appreview(body);
    setCaptchaValidity(null);
  };
  const toggleLoginModal = () => {
    setloginmodalhome((prev) => !prev);
  };
  /* const toggleSignupModal = () => {
    setsignupmodalhome((prev) => !prev);
  }; */
  const toggleMenu = () => {
    setShowMenu((prev) => !prev);
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith("image/")) {
      setImage(file);
    } else {
      // Show toast message for non-image files
      toast.error("Please select a valid image file.", toastobj);
      e.target.value = null;
    }
  };
  const toggleReview = () => {
    setReviewOpen(!reviewOpen);
  };
  const toggleLogout = () => {
    setLogout(!logout);
  };

  return (
    <div className="App">
      <ThemeSetter />
      <Modal
        isOpen={feedbackmodal}
        toggle={togglefeedback}
        className={`login-seventh journal-login-modal ${
          darkMode ? "journal-dark-button-theme" : "journal-light-button-theme"
        }`}
      >
        <ModalHeader toggle={togglefeedback}>Feedback</ModalHeader>
        <ModalBody>
          <div>
            <Form onSubmit={handleSubmit}>
              <FormGroup className="position-relative">
                <Label>Email</Label>
                <Input
                  // placeholder="Email Address"
                  type="email"
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                />
              </FormGroup>
              <FormGroup className="position-relative">
                <Label>User Name</Label>
                <Input
                  // placeholder="Username"
                  value={username}
                  onChange={(e) => setusername(e.target.value)}
                />
              </FormGroup>
              <FormGroup className="position-relative">
                <Label>Review</Label>
                <Input
                  // placeholder="Review"
                  /*  style={{
                    color: "white",
                    background: "rgba(255,255,255,0.05)",
                  }} */
                  type="textarea"
                  className="textarea-lg"
                  value={review}
                  onChange={(e) => setreview(e.target.value)}
                />
              </FormGroup>

              <FormGroup className="position-relative custom-file-input-container">
                <Label for="imageInput" className="custom-file-label">
                  Choose an Image
                </Label>
                <Input
                  type="file"
                  id="imageInput"
                  onChange={handleImageChange}
                  accept="image/*"
                  className="custom-file-input"
                />
              </FormGroup>
              <FormGroup className="position-relative">
                <Label>Captcha</Label>
                <ReCAPTCHA
                  name="recaptcha"
                  sitekey={captchaKey}
                  render="explicit"
                  theme="dark"
                  onChange={(e) => {
                    // console.log(e);
                    setCaptchaValidity(e);
                  }}
                />
              </FormGroup>

              <Button className="w-100 btn-primary mt-4">Submit</Button>
            </Form>
          </div>
        </ModalBody>
      </Modal>
      <ToastContainer />
      <BrowserRouter>
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Header
                    loginmodalhome={loginmodalhome}
                    signupmodalhome={signupmodalhome}
                    setloginmodalhome={setloginmodalhome}
                    setsignupmodalhome={setsignupmodalhome}
                    togglefeedback={togglefeedback}
                    toggleResetPass={false}
                    toggleMenu={showMenu}
                    captchaKey={captchaKey}
                    reviewOpen={reviewOpen}
                    setReviewOpen={setReviewOpen}
                    noFreeCredit={noFreeCredit}
                    logout={logout}
                    setLogout={setLogout}
                    noDaysFreeTrial={noDaysFreeTrial}
                    clearHomeState={clearHomeState}
                    toggleLoginNow={toggleLoginNow}
                    setIsActiveFreeTrial={setIsActiveFreeTrial}
                    setFreeTrialDaysLeft={setFreeTrialDaysLeft}
                  />
                  <Home
                    setloginmodalhome={setloginmodalhome}
                    setsignupmodalhome={setsignupmodalhome}
                    toggleMenu={toggleMenu}
                    noFreeTrialDays={noDaysFreeTrial}
                    toggleReview={toggleReview}
                    noFreeCredit={noFreeCredit}
                    toggleLogout={toggleLogout}
                    clearState={clearState}
                    loggedInNow={loggedInNow}
                    isActiveFreeTrialNew={isActiveFreeTrial}
                    freeTrialDaysLeftNew={freeTrialDaysLeft}
                  />
                  <FooterSeventhJournal
                    footeremail={footeremail}
                    footernum={footernum}
                    toggleMenu={toggleMenu}
                    togglefeedback={togglefeedback}
                  />
                </>
              }
            />
            <Route
              path="/master-login/:user_id/:token"
              element={<MasterLogin />}
            />
            <Route
              path="/delete-account"
              element={
                <>
                  <Header
                    togglefeedback={togglefeedback}
                    toggleMenu={showMenu}
                    captchaKey={captchaKey}
                    deleteRequest={true}
                    noFreeCredit={noFreeCredit}
                    noDaysFreeTrial={noDaysFreeTrial}
                    clearHomeState={clearHomeState}
                    toggleLoginNow={toggleLoginNow}
                    setIsActiveFreeTrial={setIsActiveFreeTrial}
                    setFreeTrialDaysLeft={setFreeTrialDaysLeft}
                  />
                  <Home
                    toggleMenu={toggleMenu}
                    noFreeCredit={noFreeCredit}
                    clearState={clearState}
                    loggedInNow={loggedInNow}
                    isActiveFreeTrialNew={isActiveFreeTrial}
                    freeTrialDaysLeftNew={freeTrialDaysLeft}
                  />
                  <FooterSeventhJournal
                    footeremail={footeremail}
                    footernum={footernum}
                    toggleMenu={toggleMenu}
                  />
                </>
              }
            />

            <Route
              path="/signin"
              element={
                <>
                  <Header
                    loginmodalhome={true}
                    setloginmodalhome={setloginmodalhome}
                    togglefeedback={togglefeedback}
                    toggleResetPass={false}
                    toggleMenu={showMenu}
                    captchaKey={captchaKey}
                    reviewOpen={reviewOpen}
                    setReviewOpen={setReviewOpen}
                    noFreeCredit={noFreeCredit}
                    noDaysFreeTrial={noDaysFreeTrial}
                    toggleLoginNow={toggleLoginNow}
                    clearHomeState={clearHomeState}
                    setIsActiveFreeTrial={setIsActiveFreeTrial}
                    setFreeTrialDaysLeft={setFreeTrialDaysLeft}
                  />
                  <Home
                    setloginmodalhome={setloginmodalhome}
                    toggleMenu={toggleMenu}
                    toggleReview={toggleReview}
                    noFreeCredit={noFreeCredit}
                    loggedInNow={loggedInNow}
                    clearState={clearState}
                    isActiveFreeTrialNew={isActiveFreeTrial}
                    freeTrialDaysLeftNew={freeTrialDaysLeft}
                  />
                  <FooterSeventhJournal
                    footeremail={footeremail}
                    footernum={footernum}
                    toggleMenu={toggleMenu}
                    togglefeedback={togglefeedback}
                  />
                </>
              }
            />
            <Route
              path="/signup"
              element={
                <>
                  <Header
                    loginmodalhome={loginmodalhome}
                    signupmodalhome={true}
                    setloginmodalhome={setloginmodalhome}
                    setsignupmodalhome={setsignupmodalhome}
                    togglefeedback={togglefeedback}
                    toggleResetPass={false}
                    toggleMenu={showMenu}
                    captchaKey={captchaKey}
                    reviewOpen={reviewOpen}
                    setReviewOpen={setReviewOpen}
                    noFreeCredit={noFreeCredit}
                    noDaysFreeTrial={noDaysFreeTrial}
                    toggleLoginNow={toggleLoginNow}
                    clearHomeState={clearHomeState}
                    setIsActiveFreeTrial={setIsActiveFreeTrial}
                    setFreeTrialDaysLeft={setFreeTrialDaysLeft}
                  />
                  <Home
                    setloginmodalhome={setloginmodalhome}
                    setsignupmodalhome={setsignupmodalhome}
                    toggleMenu={toggleMenu}
                    noFreeTrialDays={noDaysFreeTrial}
                    toggleReview={toggleReview}
                    noFreeCredit={noFreeCredit}
                    loggedInNow={loggedInNow}
                    clearState={clearState}
                    isActiveFreeTrialNew={isActiveFreeTrial}
                    freeTrialDaysLeftNew={freeTrialDaysLeft}
                  />
                  <FooterSeventhJournal
                    footeremail={footeremail}
                    footernum={footernum}
                    toggleMenu={toggleMenu}
                    togglefeedback={togglefeedback}
                  />
                </>
              }
            />
            <Route
              path="/feedback"
              element={
                <>
                  <Header
                    loginmodalhome={loginmodalhome}
                    setloginmodalhome={setloginmodalhome}
                    setsignupmodalhome={setsignupmodalhome}
                    togglefeedback={togglefeedback}
                    toggleResetPass={false}
                    toggleMenu={showMenu}
                    captchaKey={captchaKey}
                    reviewOpen={reviewOpen}
                    setReviewOpen={setReviewOpen}
                    noFreeCredit={noFreeCredit}
                    noDaysFreeTrial={noDaysFreeTrial}
                    clearHomeState={clearHomeState}
                    toggleLoginNow={toggleLoginNow}
                    setIsActiveFreeTrial={setIsActiveFreeTrial}
                    setFreeTrialDaysLeft={setFreeTrialDaysLeft}
                  />
                  <Home
                    setloginmodalhome={setloginmodalhome}
                    setsignupmodalhome={setsignupmodalhome}
                    toggleMenu={toggleMenu}
                    noFreeTrialDays={noDaysFreeTrial}
                    toggleReview={toggleReview}
                    noFreeCredit={noFreeCredit}
                    clearState={clearState}
                    loggedInNow={loggedInNow}
                    isActiveFreeTrialNew={isActiveFreeTrial}
                    freeTrialDaysLeftNew={freeTrialDaysLeft}
                  />
                  <FooterSeventhJournal
                    footeremail={footeremail}
                    footernum={footernum}
                    toggleMenu={toggleMenu}
                    togglefeedback={togglefeedback}
                  />
                </>
              }
            />
            <Route
              path="/support-chat"
              element={
                <>
                  <Header
                    loginmodalhome={loginmodalhome}
                    setloginmodalhome={setloginmodalhome}
                    setsignupmodalhome={setsignupmodalhome}
                    togglefeedback={togglefeedback}
                    toggleResetPass={false}
                    toggleMenu={showMenu}
                    supportChat={true}
                    captchaKey={captchaKey}
                    reviewOpen={reviewOpen}
                    setReviewOpen={setReviewOpen}
                    noFreeCredit={noFreeCredit}
                    noDaysFreeTrial={noDaysFreeTrial}
                    clearHomeState={clearHomeState}
                    toggleLoginNow={toggleLoginNow}
                    setIsActiveFreeTrial={setIsActiveFreeTrial}
                    setFreeTrialDaysLeft={setFreeTrialDaysLeft}
                  />
                  <Home
                    setloginmodalhome={setloginmodalhome}
                    setsignupmodalhome={setsignupmodalhome}
                    toggleMenu={toggleMenu}
                    noFreeTrialDays={noDaysFreeTrial}
                    toggleReview={toggleReview}
                    noFreeCredit={noFreeCredit}
                    clearState={clearState}
                    loggedInNow={loggedInNow}
                    isActiveFreeTrialNew={isActiveFreeTrial}
                    freeTrialDaysLeftNew={freeTrialDaysLeft}
                  />
                  <FooterSeventhJournal
                    footeremail={footeremail}
                    footernum={footernum}
                    toggleMenu={toggleMenu}
                    togglefeedback={togglefeedback}
                  />
                </>
              }
            />
            <Route
              path="/faq"
              element={
                <>
                  <Header
                    clearHomeState={clearHomeState}
                    togglefeedback={togglefeedback}
                    toggleMenu={showMenu}
                    captchaKey={captchaKey}
                    noFreeCredit={noFreeCredit}
                    noDaysFreeTrial={noDaysFreeTrial}
                  />
                  <Faq toggleMenu={toggleMenu} />
                  <FooterSeventhJournal
                    footeremail={footeremail}
                    footernum={footernum}
                    toggleMenu={toggleMenu}
                    togglefeedback={togglefeedback}
                  />
                </>
              }
            />
            <Route
              path="/contactus/:type?"
              element={
                <>
                  <Header
                    clearHomeState={clearHomeState}
                    togglefeedback={togglefeedback}
                    toggleMenu={showMenu}
                    captchaKey={captchaKey}
                    noFreeCredit={noFreeCredit}
                    noDaysFreeTrial={noDaysFreeTrial}
                  />
                  <ContactUs captchaKey={captchaKey} />
                  <FooterSeventhJournal
                    footeremail={footeremail}
                    footernum={footernum}
                    toggleMenu={toggleMenu}
                    togglefeedback={togglefeedback}
                  />
                </>
              }
            />
            <Route
              path="/dream-stories"
              element={
                <>
                  <Header
                    clearHomeState={clearHomeState}
                    loginmodalhome={loginmodalhome}
                    setloginmodalhome={setloginmodalhome}
                    togglefeedback={togglefeedback}
                    toggleMenu={showMenu}
                    captchaKey={captchaKey}
                    noFreeCredit={noFreeCredit}
                    noDaysFreeTrial={noDaysFreeTrial}
                  />
                  <DreamStories
                    toggleMenu={toggleMenu}
                    toggleLoginModal={toggleLoginModal}
                  />
                  <FooterSeventhJournal
                    footeremail={footeremail}
                    footernum={footernum}
                    toggleMenu={toggleMenu}
                    togglefeedback={togglefeedback}
                  />
                </>
              }
            />

            <Route
              path="/dreamology-arcana"
              element={
                <>
                  <Header
                    clearHomeState={clearHomeState}
                    loginmodalhome={loginmodalhome}
                    setloginmodalhome={setloginmodalhome}
                    togglefeedback={togglefeedback}
                    toggleMenu={showMenu}
                    captchaKey={captchaKey}
                    noFreeCredit={noFreeCredit}
                    noDaysFreeTrial={noDaysFreeTrial}
                  />
                  <DreamologyArcana
                    toggleMenu={toggleMenu}
                    toggleLoginModal={toggleLoginModal}
                  />
                  <FooterSeventhJournal
                    footeremail={footeremail}
                    footernum={footernum}
                    toggleMenu={toggleMenu}
                    togglefeedback={togglefeedback}
                  />
                </>
              }
            />

            <Route
              path="/analyze"
              element={
                <>
                  <Header
                    clearHomeState={clearHomeState}
                    setshowtimejournal={setshowtimejournal}
                    togglefeedback={togglefeedback}
                    toggleMenu={showMenu}
                    captchaKey={captchaKey}
                    noFreeCredit={noFreeCredit}
                    noDaysFreeTrial={noDaysFreeTrial}
                  />
                  <AnalyzeMyDreamNow
                    showtimejournal={showtimejournal}
                    toggleMenu={toggleMenu}
                    freeCredit={freeCredit}
                    freemium={freemium}
                    noFreeCredit={noFreeCredit}
                    noOfWords={noOfWords}
                  />
                </>
              }
            />
            <Route
              path="/analyze-your-dream-now"
              element={
                <>
                  <Header
                    clearHomeState={clearHomeState}
                    togglefeedback={togglefeedback}
                    captchaKey={captchaKey}
                    noFreeCredit={noFreeCredit}
                    noDaysFreeTrial={noDaysFreeTrial}
                  />
                  <AnalyzeYourDreamNow />
                </>
              }
            />
            {/* <Route
              path="/coming-soon"
              element={
                <>
                  <Header clearHomeState={clearHomeState}
                    togglefeedback={togglefeedback}
                    toggleMenu={showMenu}
                    captchaKey={captchaKey}
                    
                  />
                  <ComingSoon toggleMenu={toggleMenu} />
                  <FooterSeventhJournal
                    footeremail={footeremail}
                    footernum={footernum}
                    toggleMenu={toggleMenu}
                    togglefeedback={togglefeedback}
                  />
                </>
              }
            /> */}
            <Route
              path="/buy-gift-card"
              element={
                <>
                  <Header
                    clearHomeState={clearHomeState}
                    togglefeedback={togglefeedback}
                    toggleMenu={showMenu}
                    captchaKey={captchaKey}
                    noFreeCredit={noFreeCredit}
                    noDaysFreeTrial={noDaysFreeTrial}
                  />
                  <BuyGiftCard toggleMenu={toggleMenu} />
                  <FooterSeventhJournal
                    footeremail={footeremail}
                    footernum={footernum}
                    toggleMenu={toggleMenu}
                    togglefeedback={togglefeedback}
                  />
                </>
              }
            />
            <Route
              path="/analysisResult"
              element={
                <>
                  <Header
                    clearHomeState={clearHomeState}
                    togglefeedback={togglefeedback}
                    toggleMenu={showMenu}
                    captchaKey={captchaKey}
                    noFreeCredit={noFreeCredit}
                    noDaysFreeTrial={noDaysFreeTrial}
                  />
                  <AnalysisResult toggleMenu={toggleMenu} />
                </>
              }
            />
            <Route
              path="/terms-conditions/:type?"
              element={
                <>
                  <Header
                    clearHomeState={clearHomeState}
                    togglefeedback={togglefeedback}
                    toggleMenu={showMenu}
                    captchaKey={captchaKey}
                    noFreeCredit={noFreeCredit}
                    noDaysFreeTrial={noDaysFreeTrial}
                  />
                  <TermsConditions toggleMenu={toggleMenu} />
                  <FooterSeventhJournal
                    footeremail={footeremail}
                    footernum={footernum}
                    toggleMenu={toggleMenu}
                    togglefeedback={togglefeedback}
                  />
                </>
              }
            />
            <Route
              path="/inter-connections"
              element={
                <>
                  <Header
                    clearHomeState={clearHomeState}
                    togglefeedback={togglefeedback}
                    toggleMenu={showMenu}
                    captchaKey={captchaKey}
                    noFreeCredit={noFreeCredit}
                    noDaysFreeTrial={noDaysFreeTrial}
                  />
                  <InterConnections toggleMenu={toggleMenu} />
                  <FooterSeventhJournal
                    footeremail={footeremail}
                    footernum={footernum}
                    toggleMenu={toggleMenu}
                    togglefeedback={togglefeedback}
                  />
                </>
              }
            />
            <Route
              path="/guest"
              element={
                <>
                  <Header
                    clearHomeState={clearHomeState}
                    loginmodalhome={loginmodalhome}
                    signupmodalhome={signupmodalhome}
                    setloginmodalhome={setloginmodalhome}
                    setsignupmodalhome={setsignupmodalhome}
                    togglefeedback={togglefeedback}
                    toggleResetPass={false}
                    toggleMenu={showMenu}
                    captchaKey={captchaKey}
                    noFreeCredit={noFreeCredit}
                    noDaysFreeTrial={noDaysFreeTrial}
                  />
                  <GuestAnalysis
                    toggleMenu={toggleMenu}
                    setsignupmodalhome={setsignupmodalhome}
                  />
                  <FooterSeventhJournal
                    footeremail={footeremail}
                    footernum={footernum}
                    toggleMenu={toggleMenu}
                    togglefeedback={togglefeedback}
                  />
                </>
              }
            />
            <Route
              path="/unsubscribe/:type/:email"
              element={
                <>
                  <Unsubscribe footeremail={footeremail} />
                </>
              }
            />

            <Route
              path="/return-refund"
              element={
                <>
                  <Header
                    clearHomeState={clearHomeState}
                    togglefeedback={togglefeedback}
                    toggleMenu={showMenu}
                    captchaKey={captchaKey}
                    noFreeCredit={noFreeCredit}
                    noDaysFreeTrial={noDaysFreeTrial}
                  />
                  <ReturnRefundPolicy toggleMenu={toggleMenu} />
                  <FooterSeventhJournal
                    footeremail={footeremail}
                    footernum={footernum}
                    toggleMenu={toggleMenu}
                    togglefeedback={togglefeedback}
                  />
                </>
              }
            />
            <Route
              path="/instructional-videos"
              element={
                <>
                  <Header
                    clearHomeState={clearHomeState}
                    togglefeedback={togglefeedback}
                    toggleMenu={showMenu}
                    captchaKey={captchaKey}
                    noFreeCredit={noFreeCredit}
                    noDaysFreeTrial={noDaysFreeTrial}
                  />
                  <InstructionalVideos toggleMenu={toggleMenu} />
                </>
              }
            />
            <Route
              path="/data-subject"
              element={
                <>
                  <Header
                    clearHomeState={clearHomeState}
                    togglefeedback={togglefeedback}
                    toggleMenu={showMenu}
                    captchaKey={captchaKey}
                    noFreeCredit={noFreeCredit}
                    noDaysFreeTrial={noDaysFreeTrial}
                  />
                  <DataSubject
                    toggleMenu={toggleMenu}
                    captchaKey={captchaKey}
                  />
                </>
              }
            />

            <Route
              path="/seventhjournallisting"
              element={
                <>
                  <Header
                    clearHomeState={clearHomeState}
                    togglefeedback={togglefeedback}
                    toggleMenu={showMenu}
                    captchaKey={captchaKey}
                    noFreeCredit={noFreeCredit}
                    noDaysFreeTrial={noDaysFreeTrial}
                  />
                  <JournalListing toggleMenu={toggleMenu} />
                  <FooterSeventhJournal
                    footeremail={footeremail}
                    footernum={footernum}
                    toggleMenu={toggleMenu}
                    togglefeedback={togglefeedback}
                  />
                </>
              }
            />
            {/*  <Route
              path="/seventhjournalentry"
              element={
                <>
                  <Header clearHomeState={clearHomeState}
                    togglefeedback={togglefeedback}
                    toggleMenu={showMenu}
                    captchaKey={captchaKey}
                    
                  />
                  <SeventhJournal toggleMenu={toggleMenu} />
                </>
              }
            /> */}
            <Route
              path="/seventhjournal"
              element={
                <>
                  <Header
                    clearHomeState={clearHomeState}
                    togglefeedback={togglefeedback}
                    toggleMenu={showMenu}
                    captchaKey={captchaKey}
                    reviewOpen={reviewOpen}
                    setReviewOpen={setReviewOpen}
                    noFreeCredit={noFreeCredit}
                    loginmodalhome={loginmodalhome}
                    setloginmodalhome={setloginmodalhome}
                    noDaysFreeTrial={noDaysFreeTrial}
                  />
                  <LandingPage
                    toggleMenu={toggleMenu}
                    toggleReview={toggleReview}
                    toggleLoginModal={toggleLoginModal}
                  />
                  <FooterSeventhJournal
                    footeremail={footeremail}
                    footernum={footernum}
                    toggleMenu={toggleMenu}
                    togglefeedback={togglefeedback}
                  />
                </>
              }
            />

            <Route
              path="/beta-register"
              element={
                <>
                  <Header
                    clearHomeState={clearHomeState}
                    togglefeedback={togglefeedback}
                    toggleMenu={showMenu}
                    captchaKey={captchaKey}
                    loginmodalhome={loginmodalhome}
                    setloginmodalhome={setloginmodalhome}
                    noFreeCredit={noFreeCredit}
                    noDaysFreeTrial={noDaysFreeTrial}
                  />
                  <BetaRegister
                    toggleMenu={toggleMenu}
                    captchaKey={captchaKey}
                    setloginmodalhome={setloginmodalhome}
                  />
                </>
              }
            />

            {/* <Route path="/result" element={<ResultPage />} /> */}

            {/* <Route
            path="/dream_assistant"
            element={
              <>
                <Header clearHomeState={clearHomeState}
                  togglefeedback={togglefeedback}
                  toggleMenu={showMenu}
                  captchaKey={captchaKey}
                  
                />
                <DreamAssistant toggleMenu={toggleMenu} />
              </>
            }
          /> */}
            <Route
              path="/privacy-policy/:type?"
              element={
                <>
                  <Header
                    clearHomeState={clearHomeState}
                    fromwhere="privacy-policy"
                    togglefeedback={togglefeedback}
                    toggleMenu={showMenu}
                    captchaKey={captchaKey}
                    noFreeCredit={noFreeCredit}
                    noDaysFreeTrial={noDaysFreeTrial}
                  />
                  <PrivacyPolicy toggleMenu={toggleMenu} />
                  <FooterSeventhJournal
                    footeremail={footeremail}
                    footernum={footernum}
                    toggleMenu={toggleMenu}
                    togglefeedback={togglefeedback}
                  />
                </>
              }
            />
            {/* <Route
            path="/dream_video"
            element={
              <>
                <Header clearHomeState={clearHomeState}
                  togglefeedback={togglefeedback}
                  toggleMenu={showMenu}
                  captchaKey={captchaKey}
                  
                />
                <DreamAssistant2 toggleMenu={toggleMenu} />
              </>
            }
          /> */}

            <Route
              path="/build_your_experience"
              element={
                <>
                  <Header
                    clearHomeState={clearHomeState}
                    togglefeedback={togglefeedback}
                    toggleMenu={showMenu}
                    captchaKey={captchaKey}
                    noFreeCredit={noFreeCredit}
                    noDaysFreeTrial={noDaysFreeTrial}
                  />
                  <BuildYourExperience toggleMenu={toggleMenu} />
                </>
              }
            />
            {showSeventhArc && (
              <Route
                path="/seventharc"
                element={
                  <>
                    <Header
                      clearHomeState={clearHomeState}
                      togglefeedback={togglefeedback}
                      toggleMenu={showMenu}
                      captchaKey={captchaKey}
                      reviewOpen={reviewOpen}
                      setReviewOpen={setReviewOpen}
                      noFreeCredit={noFreeCredit}
                      noDaysFreeTrial={noDaysFreeTrial}
                    />
                    <SeventhArcPage
                      toggleMenu={toggleMenu}
                      toggleReview={toggleReview}
                    />
                    <FooterSeventhJournal
                      footeremail={footeremail}
                      footernum={footernum}
                      toggleMenu={toggleMenu}
                      togglefeedback={togglefeedback}
                    />
                  </>
                }
              />
            )}

            <Route
              path="/create_subscription"
              element={
                <>
                  <Header
                    clearHomeState={clearHomeState}
                    togglefeedback={togglefeedback}
                    loginmodalhome={loginmodalhome}
                    setloginmodalhome={setloginmodalhome}
                    // toggleLogin={toggleLoginModal}
                    toggleMenu={showMenu}
                    captchaKey={captchaKey}
                    reviewOpen={reviewOpen}
                    setReviewOpen={setReviewOpen}
                    noFreeCredit={noFreeCredit}
                    signupmodalhome={signupmodalhome}
                    setsignupmodalhome={setsignupmodalhome}
                    noDaysFreeTrial={noDaysFreeTrial}
                  />
                  <CardPayment
                    toggleLoginModal={toggleLoginModal}
                    toggleMenu={toggleMenu}
                    noFreeTrialDays={noDaysFreeTrial}
                    toggleReview={toggleReview}
                    setsignupmodalhome={setsignupmodalhome}
                  />
                  <FooterSeventhJournal
                    footeremail={footeremail}
                    footernum={footernum}
                    toggleMenu={toggleMenu}
                    togglefeedback={togglefeedback}
                  />
                </>
              }
            />

            <Route
              path="/stripegiftpayment/:refresh_session/:event_id"
              element={
                <>
                  <Header
                    clearHomeState={clearHomeState}
                    togglefeedback={togglefeedback}
                    toggleMenu={showMenu}
                    captchaKey={captchaKey}
                    noFreeCredit={noFreeCredit}
                    noDaysFreeTrial={noDaysFreeTrial}
                  />
                  <StripeGiftCardPayment toggleMenu={toggleMenu} />
                  <FooterSeventhJournal
                    footeremail={footeremail}
                    footernum={footernum}
                    toggleMenu={toggleMenu}
                    togglefeedback={togglefeedback}
                  />
                </>
              }
            />

            <Route
              path="/coming-soon-new/:scrollTo?"
              element={
                <>
                  <Header
                    clearHomeState={clearHomeState}
                    togglefeedback={togglefeedback}
                    toggleMenu={showMenu}
                    captchaKey={captchaKey}
                    noFreeCredit={noFreeCredit}
                    noDaysFreeTrial={noDaysFreeTrial}
                  />
                  <NewComingSoon toggleMenu={toggleMenu} />
                  <FooterSeventhJournal
                    footeremail={footeremail}
                    footernum={footernum}
                    toggleMenu={toggleMenu}
                    togglefeedback={togglefeedback}
                  />
                </>
              }
            />
            <Route
              path="/new-dream-assitant"
              element={
                <>
                  <Header
                    clearHomeState={clearHomeState}
                    togglefeedback={togglefeedback}
                    toggleMenu={showMenu}
                    captchaKey={captchaKey}
                    noFreeCredit={noFreeCredit}
                    noDaysFreeTrial={noDaysFreeTrial}
                  />
                  <NewDreamAssitant toggleMenu={toggleMenu} />
                  <FooterSeventhJournal
                    footeremail={footeremail}
                    footernum={footernum}
                    toggleMenu={toggleMenu}
                    togglefeedback={togglefeedback}
                  />
                </>
              }
            />
            <Route
              path="/new-instructional-videos"
              element={
                <>
                  <Header
                    clearHomeState={clearHomeState}
                    togglefeedback={togglefeedback}
                    toggleMenu={showMenu}
                    captchaKey={captchaKey}
                    noFreeCredit={noFreeCredit}
                    noDaysFreeTrial={noDaysFreeTrial}
                  />
                  <NewInstructionalVideos toggleMenu={toggleMenu} />
                  <FooterSeventhJournal
                    footeremail={footeremail}
                    footernum={footernum}
                    toggleMenu={toggleMenu}
                    togglefeedback={togglefeedback}
                  />
                </>
              }
            />

            <Route
              path="*"
              element={
                <>
                  <PageNotFound />
                </>
              }
            />

            <Route
              path="/successful-gift-card/:event_id"
              element={
                <>
                  <Header
                    clearHomeState={clearHomeState}
                    loginmodalhome={loginmodalhome}
                    setloginmodalhome={setloginmodalhome}
                    togglefeedback={togglefeedback}
                    // toggleResetPass={true}
                    successGiftCard={true}
                    toggleMenu={showMenu}
                    captchaKey={captchaKey}
                    reviewOpen={reviewOpen}
                    setReviewOpen={setReviewOpen}
                    noFreeCredit={noFreeCredit}
                    noDaysFreeTrial={noDaysFreeTrial}
                    toggleLoginNow={toggleLoginNow}
                    setIsActiveFreeTrial={setIsActiveFreeTrial}
                    setFreeTrialDaysLeft={setFreeTrialDaysLeft}
                  />
                  <Home
                    setloginmodalhome={setloginmodalhome}
                    toggleMenu={toggleMenu}
                    toggleReview={toggleReview}
                    noFreeCredit={noFreeCredit}
                    clearState={clearState}
                    loggedInNow={loggedInNow}
                    isActiveFreeTrialNew={isActiveFreeTrial}
                    freeTrialDaysLeftNew={freeTrialDaysLeft}
                  />
                  <FooterSeventhJournal
                    footeremail={footeremail}
                    footernum={footernum}
                    toggleMenu={toggleMenu}
                    togglefeedback={togglefeedback}
                  />
                </>
              }
            />
            <Route
              path="/result/:guestDataId?"
              element={
                <Result
                  toggleMenu={toggleMenu}
                  captchaKey={captchaKey}
                  setsignupmodalhome={setsignupmodalhome}
                  noFreeCredit={noFreeCredit}
                />
              }
            />
            <Route
              path="/guest-analysis/:uuid"
              element={
                <>
                  <Header
                    clearHomeState={clearHomeState}
                    togglefeedback={togglefeedback}
                    toggleMenu={showMenu}
                    captchaKey={captchaKey}
                    signupmodalhome={signupmodalhome}
                    setloginmodalhome={setloginmodalhome}
                    setsignupmodalhome={setsignupmodalhome}
                    noFreeCredit={noFreeCredit}
                    noDaysFreeTrial={noDaysFreeTrial}
                  />
                  <GuestResult
                    toggleMenu={toggleMenu}
                    setsignupmodalhome={setsignupmodalhome}
                  />
                  <FooterSeventhJournal
                    footeremail={footeremail}
                    footernum={footernum}
                    toggleMenu={toggleMenu}
                  />
                </>
              }
            />

            {/* <Route
            path="/stripeTest"
            element={<StripeTest toggleMenu={toggleMenu} />}
          /> */}

            <Route path="/auth" element={<Protected />}>
              <Route
                path="reactivate-subscription/:user_id/:subscription_id"
                element={
                  <>
                    <Header
                      clearHomeState={clearHomeState}
                      togglefeedback={togglefeedback}
                      toggleMenu={showMenu}
                      captchaKey={captchaKey}
                      noFreeCredit={noFreeCredit}
                      noDaysFreeTrial={noDaysFreeTrial}
                    />
                    <Reactivation toggleMenu={toggleMenu} />
                    <FooterSeventhJournal
                      footeremail={footeremail}
                      footernum={footernum}
                      toggleMenu={toggleMenu}
                    />
                  </>
                }
              />
              <Route
                path="account_subscription"
                element={
                  <>
                    <Header
                      clearHomeState={clearHomeState}
                      togglefeedback={togglefeedback}
                      toggleMenu={showMenu}
                      captchaKey={captchaKey}
                      noFreeCredit={noFreeCredit}
                      noDaysFreeTrial={noDaysFreeTrial}
                    />
                    <AccountSubscription toggleMenu={toggleMenu} />
                  </>
                }
              />
              <Route
                path="new-user"
                element={
                  <>
                    <Header
                      loginmodalhome={loginmodalhome}
                      setloginmodalhome={setloginmodalhome}
                      togglefeedback={togglefeedback}
                      // toggleResetPass={true}
                      toggleNewSignIn={true}
                      toggleMenu={showMenu}
                      captchaKey={captchaKey}
                      reviewOpen={reviewOpen}
                      setReviewOpen={setReviewOpen}
                      noFreeCredit={noFreeCredit}
                      noDaysFreeTrial={noDaysFreeTrial}
                      clearHomeState={clearHomeState}
                      toggleLoginNow={toggleLoginNow}
                      setIsActiveFreeTrial={setIsActiveFreeTrial}
                      setFreeTrialDaysLeft={setFreeTrialDaysLeft}
                    />
                    <Home
                      setloginmodalhome={setloginmodalhome}
                      toggleMenu={toggleMenu}
                      toggleReview={toggleReview}
                      noFreeCredit={noFreeCredit}
                      clearState={clearState}
                      loggedInNow={loggedInNow}
                      isActiveFreeTrialNew={isActiveFreeTrial}
                      freeTrialDaysLeftNew={freeTrialDaysLeft}
                    />
                    <FooterSeventhJournal
                      footeremail={footeremail}
                      footernum={footernum}
                      toggleMenu={toggleMenu}
                      togglefeedback={togglefeedback}
                    />
                  </>
                }
              />
              <Route
                path="new-beta-user"
                element={
                  <>
                    <Header
                      loginmodalhome={loginmodalhome}
                      setloginmodalhome={setloginmodalhome}
                      togglefeedback={togglefeedback}
                      // toggleResetPass={true}
                      toggleNewSignIn={true}
                      toggleMenu={showMenu}
                      newBetaUser={true}
                      captchaKey={captchaKey}
                      reviewOpen={reviewOpen}
                      setReviewOpen={setReviewOpen}
                      noFreeCredit={noFreeCredit}
                      noDaysFreeTrial={noDaysFreeTrial}
                      clearHomeState={clearHomeState}
                      toggleLoginNow={toggleLoginNow}
                      setIsActiveFreeTrial={setIsActiveFreeTrial}
                      setFreeTrialDaysLeft={setFreeTrialDaysLeft}
                    />
                    <Home
                      setloginmodalhome={setloginmodalhome}
                      toggleMenu={toggleMenu}
                      toggleReview={toggleReview}
                      noFreeCredit={noFreeCredit}
                      clearState={clearState}
                      loggedInNow={loggedInNow}
                      isActiveFreeTrialNew={isActiveFreeTrial}
                      freeTrialDaysLeftNew={freeTrialDaysLeft}
                    />
                    <FooterSeventhJournal
                      footeremail={footeremail}
                      footernum={footernum}
                      toggleMenu={toggleMenu}
                      togglefeedback={togglefeedback}
                    />
                  </>
                }
              />
              <Route
                path="seventhjournal/new-user"
                element={
                  <>
                    <Header
                      clearHomeState={clearHomeState}
                      togglefeedback={togglefeedback}
                      toggleNewSignIn={true}
                      toggleMenu={showMenu}
                      captchaKey={captchaKey}
                      noFreeCredit={noFreeCredit}
                      loginmodalhome={loginmodalhome}
                      setloginmodalhome={setloginmodalhome}
                      noDaysFreeTrial={noDaysFreeTrial}
                    />
                    <LandingPage
                      toggleMenu={toggleMenu}
                      toggleLoginModal={toggleLoginModal}
                    />
                    <FooterSeventhJournal
                      footeremail={footeremail}
                      footernum={footernum}
                      toggleMenu={toggleMenu}
                      togglefeedback={togglefeedback}
                    />
                  </>
                }
              />

              <Route
                path="paymentupdate/:fromwhere/:refresh_session?/:customer_id/:uuId?"
                element={
                  <>
                    <Header
                      clearHomeState={clearHomeState}
                      togglefeedback={togglefeedback}
                      toggleMenu={showMenu}
                      captchaKey={captchaKey}
                      noFreeCredit={noFreeCredit}
                      noDaysFreeTrial={noDaysFreeTrial}
                    />
                    <StripePaymentUpdate toggleMenu={toggleMenu} />
                    <FooterSeventhJournal
                      footeremail={footeremail}
                      footernum={footernum}
                      toggleMenu={toggleMenu}
                      togglefeedback={togglefeedback}
                    />
                  </>
                }
              />
              {/* <Route
              path="paymentupdate/:fromwhere/:refresh_session/:customer_id"
              element={
                <>
                  <Header clearHomeState={clearHomeState}
                    togglefeedback={togglefeedback}
                    toggleMenu={showMenu}
                  />
                  <StripePaymentUpdate toggleMenu={toggleMenu} />
                  <Footer
                    footeremail={footeremail}
                    footernum={footernum}
                    toggleMenu={toggleMenu}
                    togglefeedback={togglefeedback}
                  />
                </>
              }
            /> */}
              <Route
                path="freeTrialGift/:refresh_session/:customer_id/:uuId"
                element={
                  <>
                    <Header
                      clearHomeState={clearHomeState}
                      togglefeedback={togglefeedback}
                      toggleMenu={showMenu}
                      captchaKey={captchaKey}
                      noFreeCredit={noFreeCredit}
                      noDaysFreeTrial={noDaysFreeTrial}
                    />
                    <StripeFreeTrialGiftCard toggleMenu={toggleMenu} />
                    <FooterSeventhJournal
                      footeremail={footeremail}
                      footernum={footernum}
                      toggleMenu={toggleMenu}
                      togglefeedback={togglefeedback}
                    />
                  </>
                }
              />
              <Route
                path="stripepayment/:refresh_session/:uuid"
                element={
                  <>
                    <Header
                      clearHomeState={clearHomeState}
                      togglefeedback={togglefeedback}
                      toggleMenu={showMenu}
                      captchaKey={captchaKey}
                      noFreeCredit={noFreeCredit}
                      noDaysFreeTrial={noDaysFreeTrial}
                    />
                    <StripePaymentPage toggleMenu={toggleMenu} />
                    <FooterSeventhJournal
                      footeremail={footeremail}
                      footernum={footernum}
                      toggleMenu={toggleMenu}
                      togglefeedback={togglefeedback}
                    />
                  </>
                }
              />
              {false && showSeventhArc && (
                <Route
                  path="startmaintest"
                  element={
                    <>
                      <Header
                        clearHomeState={clearHomeState}
                        togglefeedback={togglefeedback}
                        toggleMenu={showMenu}
                        captchaKey={captchaKey}
                        noFreeCredit={noFreeCredit}
                        noDaysFreeTrial={noDaysFreeTrial}
                      />
                      <StartMainTest toggleMenu={toggleMenu} />
                      <FooterSeventhJournal
                        footeremail={footeremail}
                        footernum={footernum}
                        toggleMenu={toggleMenu}
                        togglefeedback={togglefeedback}
                      />
                    </>
                  }
                />
              )}

              {showSeventhArc && (
                <Route
                  path="arcana_persona/:type?/:uuid?"
                  element={
                    <>
                      <Header
                        clearHomeState={clearHomeState}
                        togglefeedback={togglefeedback}
                        toggleMenu={showMenu}
                        captchaKey={captchaKey}
                        noFreeCredit={noFreeCredit}
                        noDaysFreeTrial={noDaysFreeTrial}
                      />
                      <ArcanaPersona toggleMenu={toggleMenu} />
                      <FooterSeventhJournal
                        footeremail={footeremail}
                        footernum={footernum}
                        toggleMenu={toggleMenu}
                        togglefeedback={togglefeedback}
                      />
                    </>
                  }
                />
              )}
              <Route
                path="previous_dreams"
                element={
                  <>
                    <Header
                      clearHomeState={clearHomeState}
                      togglefeedback={togglefeedback}
                      toggleMenu={showMenu}
                      captchaKey={captchaKey}
                      noFreeCredit={noFreeCredit}
                      noDaysFreeTrial={noDaysFreeTrial}
                    />
                    <PreviousDreamsInsight toggleMenu={toggleMenu} />
                  </>
                }
              />
              <Route
                path="settings"
                element={
                  <>
                    <Header
                      clearHomeState={clearHomeState}
                      togglefeedback={togglefeedback}
                      toggleMenu={showMenu}
                      captchaKey={captchaKey}
                      noFreeCredit={noFreeCredit}
                      noDaysFreeTrial={noDaysFreeTrial}
                    />
                    <AccountSetting
                      toggleMenu={toggleMenu}
                      freeCredit={freeCredit}
                      noFreeCredit={noFreeCredit}
                    />
                  </>
                }
              />
              <Route
                path="account_info"
                element={
                  <>
                    <Header
                      clearHomeState={clearHomeState}
                      togglefeedback={togglefeedback}
                      toggleMenu={showMenu}
                      captchaKey={captchaKey}
                      noFreeCredit={noFreeCredit}
                      noDaysFreeTrial={noDaysFreeTrial}
                    />
                    <AccountInfo toggleMenu={toggleMenu} />
                  </>
                }
              />

              <Route
                path="journalresources"
                element={
                  <>
                    <Header
                      clearHomeState={clearHomeState}
                      togglefeedback={togglefeedback}
                      captchaKey={captchaKey}
                      noFreeCredit={noFreeCredit}
                      noDaysFreeTrial={noDaysFreeTrial}
                    />
                    <Resources />
                  </>
                }
              />
              <Route
                path="journalinsights"
                element={
                  <>
                    <Header
                      clearHomeState={clearHomeState}
                      togglefeedback={togglefeedback}
                      toggleMenu={showMenu}
                      captchaKey={captchaKey}
                      noFreeCredit={noFreeCredit}
                      noDaysFreeTrial={noDaysFreeTrial}
                    />
                    <JournalInsights toggleMenu={toggleMenu} />
                  </>
                }
              />
              <Route
                path="journaldiary"
                element={
                  <>
                    <Header
                      clearHomeState={clearHomeState}
                      togglefeedback={togglefeedback}
                      toggleMenu={showMenu}
                      captchaKey={captchaKey}
                      noFreeCredit={noFreeCredit}
                      noDaysFreeTrial={noDaysFreeTrial}
                    />
                    <PrintDiary toggleMenu={toggleMenu} />
                  </>
                }
              />
              <Route
                path="addjournalentry"
                element={
                  <>
                    <Header
                      clearHomeState={clearHomeState}
                      togglefeedback={togglefeedback}
                      toggleMenu={showMenu}
                      captchaKey={captchaKey}
                      noFreeCredit={noFreeCredit}
                      noDaysFreeTrial={noDaysFreeTrial}
                    />
                    <AddEntry toggleMenu={toggleMenu} />
                  </>
                }
              />
              <Route
                path="addjournalgratitude"
                element={
                  <>
                    <Header
                      clearHomeState={clearHomeState}
                      togglefeedback={togglefeedback}
                      toggleMenu={showMenu}
                      captchaKey={captchaKey}
                      noFreeCredit={noFreeCredit}
                      noDaysFreeTrial={noDaysFreeTrial}
                    />
                    <AddGratitude toggleMenu={toggleMenu} />
                  </>
                }
              />
              <Route
                path="journalsummary"
                element={
                  <>
                    <Header
                      clearHomeState={clearHomeState}
                      togglefeedback={togglefeedback}
                      toggleMenu={showMenu}
                      captchaKey={captchaKey}
                      noFreeCredit={noFreeCredit}
                      signupmodalhome={signupmodalhome}
                      setsignupmodalhome={setsignupmodalhome}
                      noDaysFreeTrial={noDaysFreeTrial}
                    />
                    <Summary toggleMenu={toggleMenu} />
                  </>
                }
              />
              <Route
                path="journalgoodhabit"
                element={
                  <>
                    <Header
                      clearHomeState={clearHomeState}
                      togglefeedback={togglefeedback}
                      toggleMenu={showMenu}
                      captchaKey={captchaKey}
                      noFreeCredit={noFreeCredit}
                      noDaysFreeTrial={noDaysFreeTrial}
                    />
                    <GoodHabit toggleMenu={toggleMenu} />
                  </>
                }
              />
              <Route
                path="journalstatistics"
                element={
                  <>
                    <Header
                      clearHomeState={clearHomeState}
                      togglefeedback={togglefeedback}
                      toggleMenu={showMenu}
                      captchaKey={captchaKey}
                      noFreeCredit={noFreeCredit}
                      noDaysFreeTrial={noDaysFreeTrial}
                    />
                    <Statistics toggleMenu={toggleMenu} />
                  </>
                }
              />
              <Route
                path="journalgoals"
                element={
                  <>
                    <Header
                      clearHomeState={clearHomeState}
                      togglefeedback={togglefeedback}
                      toggleMenu={showMenu}
                      captchaKey={captchaKey}
                      noFreeCredit={noFreeCredit}
                      noDaysFreeTrial={noDaysFreeTrial}
                    />
                    <Goals toggleMenu={toggleMenu} />
                  </>
                }
              />
              <Route
                path="journalsetting"
                element={
                  <>
                    <Header
                      clearHomeState={clearHomeState}
                      togglefeedback={togglefeedback}
                      toggleMenu={showMenu}
                      captchaKey={captchaKey}
                      noFreeCredit={noFreeCredit}
                      darkModeFromJournal={darkModeFromJournal}
                      noDaysFreeTrial={noDaysFreeTrial}
                    />
                    <JournalSetting
                      toggleMenu={toggleMenu}
                      handleDarkMode={handleDarkMode}
                    />
                  </>
                }
              />
              <Route
                path="journalcheckout"
                element={
                  <>
                    {/* Modal issue */}
                    <Header
                      clearHomeState={clearHomeState}
                      togglefeedback={togglefeedback}
                      toggleMenu={showMenu}
                      captchaKey={captchaKey}
                      noFreeCredit={noFreeCredit}
                      noDaysFreeTrial={noDaysFreeTrial}
                    />
                    <Checkout toggleMenu={toggleMenu} />
                  </>
                }
              />
              <Route
                path="myjournal"
                element={
                  <>
                    {/* Modal issue */}
                    <Header
                      clearHomeState={clearHomeState}
                      togglefeedback={togglefeedback}
                      toggleMenu={showMenu}
                      captchaKey={captchaKey}
                      noFreeCredit={noFreeCredit}
                      noDaysFreeTrial={noDaysFreeTrial}
                    />
                    <MyJournal toggleMenu={toggleMenu} />
                  </>
                }
              />
              <Route
                path="journalorderinformation"
                element={
                  <>
                    {/* Modal issue */}
                    <Header
                      clearHomeState={clearHomeState}
                      togglefeedback={togglefeedback}
                      toggleMenu={showMenu}
                      captchaKey={captchaKey}
                      noFreeCredit={noFreeCredit}
                      noDaysFreeTrial={noDaysFreeTrial}
                    />
                    <OrderInformation toggleMenu={toggleMenu} />
                  </>
                }
              />
              <Route
                path="additionalDetails"
                element={
                  <>
                    <Header
                      togglefeedback={togglefeedback}
                      toggleMenu={showMenu}
                      captchaKey={captchaKey}
                      noFreeCredit={noFreeCredit}
                      noDaysFreeTrial={noDaysFreeTrial}
                      clearHomeState={clearHomeState}
                    />
                    <AdditionalDetails toggleMenu={toggleMenu} />
                  </>
                }
              />
              <Route
                path="thank-you/:transactionId/:type?"
                element={
                  <>
                    <Header
                      clearHomeState={clearHomeState}
                      togglefeedback={togglefeedback}
                      toggleMenu={showMenu}
                      captchaKey={captchaKey}
                      noFreeCredit={noFreeCredit}
                      noDaysFreeTrial={noDaysFreeTrial}
                    />
                    <Thankyou toggleMenu={toggleMenu} />
                    <FooterSeventhJournal
                      footeremail={footeremail}
                      footernum={footernum}
                      toggleMenu={toggleMenu}
                    />
                  </>
                }
              />
              <Route
                path="dream-statistics"
                element={
                  <>
                    <Header
                      togglefeedback={togglefeedback}
                      toggleMenu={showMenu}
                      captchaKey={captchaKey}
                      noFreeCredit={noFreeCredit}
                      noDaysFreeTrial={noDaysFreeTrial}
                      clearHomeState={clearHomeState}
                    />
                    <DreamStatistics toggleMenu={toggleMenu} />
                    <FooterSeventhJournal
                      footeremail={footeremail}
                      footernum={footernum}
                      toggleMenu={toggleMenu}
                    />
                  </>
                }
              />
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

function Loading() {
  return <Loader state={true} />;
}
export default App;
